import React, { Component } from 'react';

import './index.scss';
import { AppStateContext } from 'src/contexts/app-state-provider.context';
import Layout from 'src/components/layout';
import Annotation from 'src/components/annotation';
import { TranslatableText, TranslatedBlock } from 'src/components/language';

import VialImage from 'src/images/amp30-new.jpg';

class PatientPage extends Component {
  static contextType = AppStateContext;
  componentDidMount() {
    this.context.setSection('patient');
    this.context.setPage('patient');
  }

  render() {
    return (
      <Layout
        title={{
          english: 'What is HEMLIBRA?',
          french: 'Qu’est-ce que HEMLIBRA?',
        }}
        showTab={true}
        className="patient"
      >
        <h2>
          <TranslatableText
            dictionary={{
              english: 'WHAT IS HEMLIBRA?',
              french: 'QU’EST-CE QUE HEMLIBRA?',
            }}
          />
        </h2>

        <div className="hemlibra-info">
          <div className="vial-img-container">
            <img src={VialImage} alt="HEMLIBRA 30 mg Sky Blue vial" className={'vial-img'} />
            <TranslatableText
              dictionary={{
                english: 'Vial of HEMLIBRA',
                french: 'Fiole de HEMLIBRA',
              }}
            />
          </div>
          <div className="hemlibra-info-text">
            <TranslatedBlock language="english">
              <p>
                HEMLIBRA, otherwise known as emicizumab, belongs to a group of medicines called "monoclonal antibodies".
              </p>
              <p>
                HEMLIBRA is used to treat people of all age groups who have hemophilia A (a bleeding condition people
                can be born with), which is caused by a missing or faulty blood clotting factor (factor VIII) that
                prevents blood from clotting normally. Please consult the Consumer Information leaflet contained in your
                product package for important safety information.
              </p>
              <p>HEMLIBRA is used for routine prophylaxis to prevent or reduce the frequency of bleeding episodes.</p>
              <p>
                This website has the administration video to aid with HEMLIBRA injections and downloadable patient
                materials to educate patients and caregivers on the treatment.
              </p>
            </TranslatedBlock>
            <TranslatedBlock language="french">
              <p>
                HEMLIBRA, également connu sous le nom d’émicizumab, appartient à un groupe de médicaments appelés
                «&nbsp;anticorps monoclonaux&nbsp;».
              </p>
              <p>
                HEMLIBRA est utilisé dans tous les groupes d’âge pour traiter l’hémophilie A (un trouble hémorragique
                avec lequel peuvent naître certaines personnes), une maladie causée par l’absence ou le mauvais
                fonctionnement d’un facteur de coagulation (le facteur VIII), ce qui peut empêcher le sang de coaguler
                normalement. Veuillez consulter le feuillet Renseignements pour le consommateur que vous trouverez dans
                l’emballage du produit pour obtenir des renseignements importants sur l’innocuité.
              </p>
              <p>
                HEMLIBRA est indiqué en prophylaxie de routine pour prévenir les hémorragies ou réduire la fréquence des
                épisodes hémorragiques.
              </p>
              <p>
                Ce site Web contient la vidéo sur l’administration de HEMLIBRA, un guide sur la façon de faire les
                injections, ainsi que du matériel téléchargeable destiné aux patients, qui informe les patients et leurs
                soignants au sujet du traitement.
              </p>
            </TranslatedBlock>
          </div>
        </div>
        <div>
          <hr />
        </div>

        <Annotation />
      </Layout>
    );
  }
}

export default PatientPage;
